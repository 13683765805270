import React from 'react'
import { AiFillGithub, AiOutlineArrowRight, AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai"
import { BsFacebook } from "react-icons/bs"
import { FaLinkedinIn } from "react-icons/fa"
import { TbWorld } from "react-icons/tb"
import mQ from '../../assets/mQ.png'
import Container from "../container/Container"
const FooterSection = () => {
  return (
    <Container backGroundClasses={"bg-slate-900 py-14 mt-16"}>
      <div className='flex space-x-8'>
        <div className='w-[40%]'>
          <div className='flex items-center text-white text-3xl font-bold space-x-2'>
            <img src={mQ} alt='' />
            <h2 >
              MindQuest <br /> Studio
            </h2>
          </div>
          <p className="text-lg mt-7 text-justify font-light text-slate-300 w-[90%]">
            Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment.
          </p>
        </div>
        <div className='w-[20%] text-slate-300'>
          <h2 className='font-semibold text-white text-md'>
            Company
          </h2>
          <ul className=' space-y-3 mt-10'>
            <li><a href='#'>About Us</a></li>
            <li><a href='#'>Careers</a></li>
            <li><a href='#'>Blog</a></li>
            <li><a href='#'>Pricing</a></li>
          </ul>
        </div>
        <div className='w-[20%] text-slate-300'>
          <h2 className='font-semibold text-white text-md'>
            Resources
          </h2>
          <ul className=' space-y-3 mt-10'>
            <li><a href='#'>Proposal Template</a></li>
            <li><a href='#'>Invoice Template</a></li>
            <li><a href='#'>Tuturoial</a></li>
            <li><a href='#'>How to write a contract</a></li>
          </ul>
        </div>
        <div className='w-[20%] text-slate-300'>
          <h2 className='font-semibold text-white text-md'>
            Product
          </h2>
          <ul className=' space-y-3 mt-10'>
            <li><a href='#'>Invoicing Platform</a></li>
            <li><a href='#'>Accounting Plateform</a></li>
            <li><a href='#'>Create Proposal</a></li>
            <li><a href='#'>Contracts</a></li>
          </ul>
        </div>

      </div>
      <div className='flex items-center mt-20  group'>
        <div className=' bg-white h-[1px] w-full relative '>
          <div className='bg-white h-[3px] w-0 group-hover:w-full drop-shadow-[0_0px_20px_rgb(255,255,255,1)] absolute top-0 right-0 duration-1000'></div>
        </div>
        <div className='flex space-x-4 text-white text-3xl mx-2 items-center group'>
          <a href='#'>

            <AiFillGithub className=' hover:scale-150 duration-300 hover:drop-shadow-[0_0px_10px_rgb(255,255,255,0.7)]' />
          </a><a href='#'>

            <AiOutlineTwitter className=' hover:scale-150 duration-300 hover:drop-shadow-[0_0px_10px_rgb(255,255,255,0.7)]' />
          </a><a href='#'>

            <FaLinkedinIn className=' hover:scale-150 duration-300 hover:drop-shadow-[0_0px_10px_rgb(255,255,255,0.7)]' />
          </a><a href='#'>

            <AiOutlineInstagram className=' hover:scale-150 duration-300 hover:drop-shadow-[0_0px_10px_rgb(255,255,255,0.7)]' />
          </a><a href='#'>
            <BsFacebook className=' hover:scale-150 duration-300 hover:drop-shadow-[0_0px_10px_rgb(255,255,255,0.7)]' />

          </a>
        </div>
        <div className=' bg-white h-[1px] w-full relative '>
          <div className='bg-white h-[3px] w-0 group-hover:w-full drop-shadow-[0_0px_20px_rgb(255,255,255,1)] absolute top-0 left-0 duration-1000'></div>
        </div>
      </div>
      <div className='flex text-white font-medium justify-between items-center mt-7'>
        <a href='#' className='flex items-center hover:text-sky-400 duration-300' >20222 All Rights Reserved <AiOutlineArrowRight className='mt-1 ml-1' /></a>
        <a href='#' className='flex items-center hover:text-sky-400 duration-300' ><TbWorld className='mt-1 mr-1' />mindquest.studio</a>
      </div>
    </Container>
  )
}

export default FooterSection