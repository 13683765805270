import React from 'react'
import Container from '../container/Container'

const WhyChoosUs = () => {
    return (
        <Container>
            <div className='bg-slate-100 rounded-lg px-20 py-14'>
                <div className='text-center'>
                    <h2 className="font-medium text-[#3734A9] mb-2">Why Financy</h2>
                    <h1 className="font-bold text-[40px]">
                        Why Choose Us
                    </h1>
                </div>
                <div className="grid grid-cols-2 gap-10 mt-10">
                    <div className='rounded-xl  bg-white flex justify-between px-8 py-10 text-slate-600 space-x-5'>
                        <div className='w-[15%]'>
                            <div className=' bg-purple-200 w-12 h-12 rounded-full'></div>
                        </div>
                        <div className='w-[85%]'>
                            <h2 className="font-bold text-gray-700 text-xl ">No Extra Fee</h2>
                            <p className="text-md font-normal mt-5 ">
                                End-to-end payments and financial management in a single solution. Meet the right platform.
                            </p>
                        </div>
                    </div>
                    <div className='rounded-xl  bg-white flex justify-between px-8 py-10 text-slate-600 space-x-5'>
                        <div className='w-[15%]'>
                            <div className=' bg-sky-200 w-12 h-12 rounded-full'></div>
                        </div>
                        <div className='w-[85%]'>
                            <h2 className="font-bold text-gray-700 text-xl ">No Extra Fee</h2>
                            <p className="text-md font-normal mt-5 ">
                                End-to-end payments and financial management in a single solution. Meet the right platform.
                            </p>
                        </div>
                    </div>
                    <div className='rounded-xl  bg-white flex justify-between px-8 py-10 text-slate-600 space-x-5'>
                        <div className='w-[15%]'>
                            <div className=' bg-rose-200 w-12 h-12 rounded-full'></div>
                        </div>
                        <div className='w-[85%]'>
                            <h2 className="font-bold text-gray-700 text-xl ">No Extra Fee</h2>
                            <p className="text-md font-normal mt-5 ">
                                End-to-end payments and financial management in a single solution. Meet the right platform.
                            </p>
                        </div>
                    </div>
                    <div className='rounded-xl  bg-white flex justify-between px-8 py-10 text-slate-600 space-x-5'>
                        <div className='w-[15%]'>
                            <div className=' bg-green-200 w-12 h-12 rounded-full'></div>
                        </div>
                        <div className='w-[85%]'>
                            <h2 className="font-bold text-gray-700 text-xl ">No Extra Fee</h2>
                            <p className="text-md font-normal mt-5 ">
                                End-to-end payments and financial management in a single solution. Meet the right platform.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </Container>
    )
}

export default WhyChoosUs