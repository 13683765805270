import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import React from "react";
import Group1 from "../../assets/Group (1).png";
import Group2 from "../../assets/Group (2).png";
import Group3 from "../../assets/Group (3).png";
import Group4 from "../../assets/Group (4).png";
import Group5 from "../../assets/Group (5).png";
import Container from "../container/Container";
const CompanyLogoSlider = () => {
  const companyLogo = [
    Group1,
    Group2,
    Group3,
    Group4,
    Group5,
    Group2,
    Group4,
    Group3,
  ];
  return (
    <Container>
      <div className=" justify-center m-auto text-center py-28">
        <h2 className="mb-10 text-base font-medium">
          Over 32k+ Enterprise businesses growing with MindQuest
        </h2>
        <div>
          <Splide
            options={{
              perPage: 5,
              arrows: false,
              pagination: false,
              drag: "free",
            }}
          >
            {companyLogo.map((company, index) => (
              <SplideSlide key={index}>
                <img src={company} alt="" />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </Container>
  );
};

export default CompanyLogoSlider;
