import "./App.css";
import BannerSection from "./component/bannerSection/BannerSection";
import OurFeature from "./component/featureSection/OurFeature";
import FooterSection from "./component/footerSection/FooterSection";
import NavBar from "./component/navBar/NavBar";
import CompanyLogoSlider from "./component/Slider/CompanyLogoSlider";
import Testimonials from "./component/testimonials/Testimonials";
import WhyChoosUs from "./component/WhyChoosUs/WhyChoosUs";

function App() {
  return (
    <div>
      <NavBar />
      <BannerSection />
      <CompanyLogoSlider />
      <OurFeature />
      <Testimonials />
      <WhyChoosUs />
      <FooterSection />
    </div>
  );
}

export default App;
