import React from "react";

const Container = ({ children, backGround, backGroundClasses }) => {
  return (
    <div className={`${backGroundClasses}`}>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 ">{children}</div>
    </div>
  );
};

export default Container;
