import React from "react";
import bird from "../../assets/62.png";
import featureGrap from "../../assets/Illustration (1).png";
import Container from "../container/Container";
const OurFeature = () => {
  return (
    <Container>
      <div className="py-20">
        
        <div className="flex justify-between ">
          <LeftSideContent />
          <RightSideContent />
        </div>
        <div className=" bg-slate-100 rounded-lg p-12 flex justify-between space-x-28">
          <div className="w-[50%]">
            <h2 className="font-medium text-[#3734A9] mb-2">Our Feature</h2>
            <h1 className="font-bold text-[45px]">
              Receive payments quickly from anywhere
            </h1>
            <p className="text-lg mt-7 text-justify font-light">
              Why kept very ever home mrs. Considered sympathize ten uncommonly
              occasional assistance sufficient not. Letter of on become he tended
              active enable to.
            </p>
          </div>
          <div className="relative">
            <img src={bird} alt="" className=" absolute -top-20 -right-10" />
            <h2 className="font-medium text-[#3734A9] mb-2 text-xl">Get Started for Free</h2>
            <form className="w-[75%]">
              <input type="text" placeholder="Email Address" className="w-full py-3 px-4 my-3 rounded-sm drop-shadow-sm bg-white outline-none" />
              <input type="password" placeholder="Password" className="w-full py-3 px-4 my-3 rounded-sm drop-shadow-sm bg-white outline-none" />
              <button className="w-full py-4 my-3 text-sm text-white bg-[#FF7F5C] rounded-md drop-shadow-[0_25px_25px_rgb(255,127,92,0.2)]">
                Get Started
              </button>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default OurFeature;

const LeftSideContent = () => {
  return (
    <div className="w-50%]">
      <img src={featureGrap} alt="" className="-ml-24 " />
    </div>
  );
};

const RightSideContent = () => {
  return (
    <div className="w-[50%]">
      <h2 className="font-medium text-[#3734A9]">Our Feature</h2>
      <h1 className="font-bold text-[45px]">
        Receive payments quickly from anywhere
      </h1>
      <p className="text-lg my-7 text-justify font-light">
        Why kept very ever home mrs. Considered sympathize ten uncommonly
        occasional assistance sufficient not. Letter of on become he tended
        active enable to.
      </p>
      <button className="px-9 py-4 text-sm text-white bg-[#3734A9] rounded-md shadow-[#3734A9] drop-shadow-[0_25px_25px_rgb(55,52,169,0.2)]">
        Get Started
      </button>
    </div>
  );
};
