import React from 'react'
import cardImg from "../../assets/Cards.png"
import Container from '../container/Container'
const Testimonials = () => {
    return (
        <Container>

            <div className='flex py-20'>
                <div className='w-[50%] my-auto'>
                    <h2 className="font-medium text-[#3734A9]">Our Feature</h2>
                    <h1 className="font-bold text-[45px]">
                        Receive payments quickly from anywhere
                    </h1>
                    <p className="text-lg my-7 text-justify font-light">
                        Why kept very ever home mrs. Considered sympathize ten uncommonly
                        occasional assistance sufficient not. Letter of on become he tended
                        active enable to.
                    </p>
                    <button className="px-9 py-4 text-sm text-white bg-[#3734A9] rounded-md drop-shadow-2xl shadow-[#3734A9] drop-shadow-[0_25px_25px_rgb(55,52,169,0.2)]">
                        Get Started
                    </button>
                </div>

                <div className='w-[50%]'>
                    <img src={cardImg} alt="" />
                </div>
            </div>
        </Container>
    )
}

export default Testimonials