import React from "react";
import { FaPlay } from "react-icons/fa";
import bannerBg from "../../assets/bannerBg.png";
import Container from "../container/Container";
const BannerSection = () => {
  return (
    <Container backGround={bannerBg} backGroundClasses={""}>
      <img
        src={bannerBg}
        alt=""
        className=" absolute top-20 left-0 object-cover w-[100%] -z-10"
      />
      <div className="flex justify-between mt-24">
        <LeftSide />
        <RightSide />
      </div>
    </Container>
  );
};

export default BannerSection;

const LeftSide = () => {
  return (
    <div className="w-[50%]">
      <h1 className="font-bold text-[58px]">
        Managing business process has never been easier
      </h1>
      <p className="text-lg my-7">
        End-to-end payments and financial management in a single solution. Meet
        the right platform to help realize.
      </p>
      <div className="flex items-center font-medium">
        <button className="px-9 py-4 text-sm text-white bg-violet-500 hover:bg-violet-600 rounded-full drop-shadow-[0_25px_25px_rgb(55,52,169,0.2)]">
          Get Started
        </button>
        <button className="flex justify-center items-center px-9 py-4">
          <div className=" px-4 py-4 rounded-full bg-green-400 text-white mr-2 drop-shadow-[0_25px_25px_rgb(74,222,128,0.3)]">
            <FaPlay />
          </div>
          Show How It Work
        </button>
      </div>
    </div>
  );
};

const RightSide = () => {
  return (
    <div
      className="w-[50%] 
  "
    >
      
    </div>
  );
};
